import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { fontSizing } from "../styles/fontSizing";
import { sizing } from "../styles/sizing";

const Background = styled.div({
  borderTop: "50px solid #401060",
});

const Container = styled.div({
  "@media(min-width:535px)": {
    height: "calc(100vh - 100px)",
  },
  display: "flex",
  flexFlow: "column",
  width: "100%",
  maxWidth: 1080,
  margin: `0 auto`,
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 158px)",
});

const RGB = keyframes({
  "0%": { color: "red" },
  "25%": { color: "blue" },
  "50%": { color: "green" },
  "75%": { color: "blue" },
  "100%": { color: "red" },
});

const Name = styled.div({
  ...fontSizing.t8xl,
  color: "#2F0C46",
  marginBottom: sizing["5"],
  textAlign: "center",
  fontFamily: "morris",
});

const Paragraph = styled.p({
  ...fontSizing.t7xl,
  color: "#401060",
  textAlign: "center",
  letterSpacing: ".17rem",
});

const ColorChanger = styled.span({
  "&:hover": {
    animation: `${RGB} 2s ease infinite`,
  },
});

export const Home = () => {
  return (
    <Background>
      <Container>
        <div>
          <Name>Jesus Urias</Name>
          <Paragraph>
            Full-Stack Developer, Gamer, &
            <ColorChanger> RGB Enthusiast</ColorChanger>.
          </Paragraph>
        </div>
      </Container>
    </Background>
  );
};
