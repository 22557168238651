import styled from "@emotion/styled";
import { LinkedInIcon } from "./icons/LinkedInIcon";
import { GitHubIcon } from "./icons/GitHubIcon";
import { fontSizing } from "../styles/fontSizing";
import { sizing } from "../styles/sizing";

const Container = styled.div({
  "@media(min-width:535px)": {
    display: "flex",
  },
  display: "block",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: 1080,
  margin: `0 auto`,
  width: "100%",
});

const LinkName = styled.p({
  ...fontSizing.t4xl,
  marginRight: sizing["1"],
  color: "#401060",
});

const Link = styled.div({
  display: "flex",
  alignItems: "center",
});

export const Links = () => {
  return (
    <Container>
      <Link>
        <LinkName>LinkedIn:</LinkName>
        <a href={"https://www.linkedin.com/in/jesus-urias/"}>
          <LinkedInIcon />
        </a>
      </Link>
      <Link>
        <LinkName>GitHub:</LinkName>
        <a href={"https://github.com/jurias90"}>
          <GitHubIcon />
        </a>
      </Link>
    </Container>
  );
};
