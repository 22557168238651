import React from "react";
import { Home } from "./components/Home";
import { Links } from "./components/Links";
import styled from "@emotion/styled";
import image from "./assets/background.jpg";

const Background = styled.div({
  background: `radial-gradient(circle, rgba(255,255,255,.5) 0%, rgba(255,255,255,0) 80%), url(${image})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
});

const App = () => {
  return (
    <Background>
      <Home />
      <Links />
    </Background>
  );
};

export default App;
